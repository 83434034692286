<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-card>
        <v-card-title>
          Ajouter une publicite
          <v-spacer/>
          <v-btn class="v-btn--active" color="primary" icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-6" style="overflow-y: scroll; max-height: 450px">
          <form enctype="multipart/form-data" @submit.prevent="send">
            <v-text-field v-model="form.name" :error-messages="formErrors && formErrors.name ? formErrors.name[0] : ''
    " label="Désignation *" @input="
              formErrors && formErrors.name ? (formErrors.name = '') : false
              "/>

            <v-select v-if="can('admin')" v-model="form.type" :error-messages="formErrors.type" :items="pubTypes" dense
                      filled
                      item-text="name"
                      item-value="id" label="Type" @input="
                formErrors && formErrors.type ? (formErrors.type = '') : false
                ">
            </v-select>

            <v-select v-if="can('admin') && form.type == 0" v-model="form.positioning"
                      :error-messages="formErrors.positioning" :items="publicityPositionings()" chips
                      deletable-chips dense label="Emplacement" multiple @input="
                formErrors && formErrors.positioning
                  ? (formErrors.positioning = '')
                  : false
                ">
            </v-select>

            <PublicityCycle v-if="form.type == 2 || form.type == 1" @change="cycleChanged($event)"/>
            <p v-if="formErrors && formErrors.cycle" class="text-danger mt-0">
              {{ formErrors.cycle[0] }}
            </p>


            <label class="d-block mt-6"> Type action * </label>

            <v-chip-group v-model="form.action_type" center-active mandatory>
              <v-chip active-class="gifty white--text" filter label value="external">
                Lien Externe
                <v-icon dense right>mdi-arrow-top-right-thick</v-icon>
              </v-chip>

              <v-chip active-class="gifty white--text" filter label value="internal">
                Service interne
                <v-icon dense right>mdi-arrow-down-bold</v-icon>
              </v-chip>
            </v-chip-group>

            <v-text-field v-if="form.action_type == 'external'" v-model="form.action"
                          :error-messages="formErrors && formErrors.action ? formErrors.action[0] : '' "
                          label="Action (Lien)"
                          @input="
              formErrors && formErrors.action
                ? (formErrors.action = '')
                : false
              "/>


            <v-select v-if="form.action_type == 'internal'" v-model="form.action_service_id" :disabled="loadingService"
                      :error-messages="formErrors.action_service_id"
                      :item-text="'name'" :item-value="'id'" :items="services" :loading="loadingService" chips clearable
                      deletable-chips label="Service interne*" small-chips>
              <template v-slot:item="slotProps">
                <v-avatar class="mr-2" size="35">
                  <v-img :src="getImageLink(slotProps.item.image)" contain/>
                </v-avatar>
                {{ slotProps.item.name }}
              </template>
            </v-select>


            <v-textarea v-model="form.description" hint="Description ...." label="Description"
                        name="input-7-1"></v-textarea>

            <div class="d-flex align-center">
              <v-file-input ref="file" :error-messages="formErrors.image"
                            accept="image/png, image/jpeg, image/jpg, .svg, .gif"
                            chips
                            label="Image *" prepend-icon="mdi-camera" show-size suffix="Image" @change="uploadFile"
                            @focus="
                  formErrors && formErrors.image
                    ? (formErrors.image = '')
                    : false
                  "/>
              <v-btn v-if="form.image" class="primary white--text ml-2" depressed icon small
                     @click="previewImage(form.image)">
                <v-icon small>mdi mdi-eye</v-icon>
              </v-btn>

              <v-btn v-if="form.image" class="primary white--text ml-2" depressed icon small
                     @click="cropImage(form.image)">
                <v-icon small>mdi mdi-crop</v-icon>
              </v-btn>
            </div>

            <p v-if="errorCrop" class="error--text">
              {{ errorCrop }}
            </p>
            <v-img :src="croppedCanvaImage" width="100%"/>

            <!-- VIDEO -->
            <div v-if="form.type == 2">
              <label class="d-block mt-6"> Choix video * </label>

              <v-chip-group v-model="video_type" center-active mandatory>
                <v-chip active-class="gifty white--text" filter label value="youtube_link">
                  Video Youtube
                  <v-icon dense right>mdi-youtube</v-icon>
                </v-chip>

                <v-chip active-class="gifty white--text" filter label value="video_file">
                  Importer une video
                  <v-icon dense right>mdi-video</v-icon>
                </v-chip>
              </v-chip-group>

              <div v-if="video_type == 'video_file'" class="d-flex align-center">
                <v-file-input ref="video" :error-messages="formErrors.video" accept="video/mp4" chips label="Video *"
                              prepend-icon="mdi-video" show-size suffix="Video" @change="uploadVideo" @focus="
                  formErrors && formErrors.video
                    ? (formErrors.video = '')
                    : false
                  "/>
              </div>

              <div v-if="video_type == 'youtube_link'" class="d-flex align-center">
                <v-text-field v-model="video_link" :error-messages="formErrors.video" chips label="Lien Youtube *"
                              prepend-icon="mdi-youtube" show-size suffix="Video" @focus="
                  formErrors && formErrors.video
                    ? (formErrors.video = '')
                    : false
                  "/>
              </div>
            </div>
            <!-- VIDEO -->

            <DateRange :dates.sync="form.dates"
                       :error-messages="formErrors.dates"
                       text-info="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ut porta dolor"
                       @dates="form.dates = $event" @input="
                formErrors && formErrors.dates ? (formErrors.dates = '') : false
                "/>
            <p v-if="formErrors && formErrors.dates" class="text-danger mt-0">
              {{ formErrors.dates[0] }}
            </p>

            <v-row>
              <v-col>
                <v-menu v-model="dateMenu" :close-on-content-click="false" :nudge-right="40"
                        min-width="auto"
                        offset-y transition="scale-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="form.send_day" label="Date d'envoi *"
                                  prepend-inner-icon="mdi-calendar-outline"
                                  readonly v-bind="attrs" v-on="on"></v-text-field>
                    <p v-if="formErrors && formErrors.send_day" class="text-danger mt-0">
                      {{ formErrors.send_day[0] }}
                    </p>
                    <p v-if="formErrors && formErrors.send_date" class="text-danger mt-0">
                      {{ formErrors.send_date[0] }}
                    </p>
                  </template>
                  <v-date-picker v-model="form.send_day" :min="form.send_day" @input="dateMenu = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu ref="menu" v-model="timeMenu" :close-on-content-click="false" :nudge-right="40"
                        :return-value.sync="form.send_hour" min-width="auto" offset-y transition="scale-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="form.send_hour" label="Heure d'envoi *"
                                  prepend-inner-icon="mdi-clock-outline"
                                  readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-time-picker v-model="form.send_hour"
                                 @click:minute="$refs.menu.save(form.send_hour)"></v-time-picker>
                </v-menu>

                <p v-if="formErrors && formErrors.send_date" class="text-danger mt-0">
                  {{ formErrors.send_date[0] }}
                </p>
              </v-col>
            </v-row>


            <v-checkbox v-model="form.display_in_home"
                        label="Afficher sur la page d'accueil ?"/>

            <v-autocomplete multiple
                            dense
                            chips
                            deletable-chips
                            small-chips
                            v-model="form.serviceIds"
                            :error-messages="formErrors.serviceIds"
                            :items="services"
                            filled
                            hide-details="auto"
                            item-text="name"
                            item-value="id"
                            label="Services qui affichent seulement *"
            ></v-autocomplete>


            <v-checkbox v-model="form.is_default" hide-details label="Visible pour tout le monde"></v-checkbox>

            <v-radio-group v-if="!form.is_default"
                           v-model="form.users_assignment_type"
                           label="Affectation *" row>
              <v-radio label="Choisir" value="specify"/>
              <v-radio label="Exclure" value="exclude"/>
            </v-radio-group>

            <div class="d-flex justify-space-between">
              <div>
                <v-btn v-if="!form.is_default" class="secondary rounded-lg mt-3" depressed large @click="selectUsers">
                  Affecter des Utilisateurs ({{ form.userIds.length }})
                </v-btn>
                <p v-if="formErrors && formErrors.userIds" class="text-danger mt-0">
                  {{ formErrors.userIds[0] }}
                </p>
              </div>
              <v-btn :loading="btnLoading" class="rounded-lg text-none mt-3" color="primary" depressed large
                     @click="send">
                <v-icon left>mdi-send-outline</v-icon>
                Envoyer
              </v-btn>
            </div>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <SelectUsers ref="selectUsers" @selected="setUsers($event)"/>
    <ImageCropper ref="imageCropper" @cropped="imageCropped($event)"/>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import SelectUsers from "./SelectUsers.vue";
import moment from "moment";
import ImageCropper from "@/components/ImageCropper.vue";
import PublicityCycle from "./PublicityCycle.vue";

export default {
  components: {
    SelectUsers,
    ImageCropper,
    PublicityCycle,
  },

  data() {
    return {
      btnLoading: false,
      dialog: false,
      formErrors: [],
      errors: null,
      errorCrop: "",

      loadingService: false,
      services: [],

      form: {
        image: "",
        name: "",
        description: "",
        dates: [],
        type: 0,
        action: "",
        action_type: "external",
        action_service_id: '',
        background_color: "#000000",
        userIds: [],
        send_day: moment().format("YYYY-MM-DD"),
        send_hour: moment().add(5, "minutes").format("HH:mm"),
        is_default: false,
        filter: "",
        cycle: "",
        positioning: "",
        users_assignment_type: 'specify',

        display_in_home: false,
        serviceIds: [],
      },

      dateMenu: false,
      timeMenu: false,
      canvas: null,

      language_tab: null,


      video_type: "youtube_link", // 0: youtube_link, 1: video_file
      video_file: null,
      video_link: ""
    };
  },

  computed: {
    urlImage() {
      if (this.form.image == null) {
        return "";
      }
      return URL.createObjectURL(this.form.image);
    },
    croppedCanvaImage() {
      return this.getCanvas64();
    },
  },
  methods: {
    imageCropped(canvas) {
      this.canvas = canvas;

      console.log("Canvas added");
      console.log(canvas);
    },
    getCanvas64() {
      if (this.canvas) {
        return this.canvas.toDataURL("image/jpeg", 1);
      }
      return "";
    },
    cropImage() {
      if (this.form.type == 0) {
        this.$refs.imageCropper.open(this.form.image);
      }
    },

    setUsers(e) {
      this.form.userIds = e.users;
      this.form.filter = e.filter;
    },

    selectUsers() {
      this.$refs.selectUsers.open();
    },
    open() {

      if (this.$refs.file) {
        this.$refs.file.reset();
      }

      this.dialog = true;
    },

    getServices() {
      this.loadingService = true;
      HTTP.get("/v1/promotions/services", {params: {}}).then((res) => {
        this.services = res.data.data;
        this.loadingService = false;
      }).catch((err) => {
        this.loadingService = false;
        console.log(err);
      });
    },

    close() {
      this.formErrors = {};
      this.dialog = false;
      this.canvas = null;
    },
    send() {
      this.formErrors = {};
      if (this.canvas == null && this.form.image & (this.form.type == 0)) {
        this.errorCrop = "Veuillez redimentionner l'image";
        this.cropImage();
        return;
      }
      this.btnLoading = true;

      let formData = new FormData();

      if (this.form.is_default) {
        this.form.is_default = 1;
      } else {
        this.form.is_default = 0;
      }

      if (this.form.display_in_home) {
        this.form.display_in_home = 1;
      } else {
        this.form.display_in_home = 0;
      }

      Object.keys(this.form).forEach((key) => {
        formData.append(key, this.form[key]);
      });

      if (this.form.dates.length > 0) {
        formData.append("dates[]", this.form.dates[0]);
        formData.append("dates[]", this.form.dates[1]);
      }

      // this.form.userIds.forEach((element) => {
      //   formData.append("userIds[]", element);
      // });


      formData.append(
          "send_date",
          this.form.send_day + " " + this.form.send_hour + ":00"
      );

      // set Canvas Image
      if (this.form.type == 0 && this.form.image.type != "image/gif") {
        formData.delete("image");
        formData.append("image", this.getCanvas64());
      }

      if (this.form.type == 2) {
        if (this.video_type == 'video_file') {
          formData.append("video", this.video_file);
        }

        if (this.video_type == 'youtube_link') {
          formData.append("youtube_link", this.video_link);
        }
      }

      HTTP.post("/V2/publicities/store", formData)
          .then(() => {
            this.$successMessage = "Ce Service a été crée avec succès";
            this.btnLoading = false;
            this.close();
            this.form = {
              image: "",
              name: "",
              description: "",
              action_type: "external",
              action_service_id: '',

              dates: [],
              type: 0,
              background_color: "#000000",
              userIds: [],
              serviceIds: [],
              is_default: false,
              display_in_home: false,
              users_assignment_type: 'specify'
            };
            this.$refs.file.reset();
            this.$emit("refresh");

            this.dialog = false;
          })
          .catch((err) => {
            this.btnLoading = false;
            this.formErrors = err.response.data.errors;
          });
    },

    uploadFile(file) {
      if (this.form.type == 0) {
        this.canvas = null;
        if (file) {
          this.form.image = file;

          if (file.type != "image/gif") {
            this.cropImage();
          }
        } else {
          this.form.image = "";
        }
      } else {
        this.form.image = file;
      }
    },

    uploadVideo(file) {
      this.video_file = file;
    },

    cycleChanged(event) {
      this.form.cycle = JSON.stringify(event);
    },
  },
  created() {
    this.getServices();
  },
};
</script>

<style scoped>
.text-danger {
  color: #ff5252 !important;
  margin-top: 0;
}
</style>
    