<template>
  <div>
    <v-select
      :items="pubCycles"
      item-value="value"
      item-text="label"
      label="Cycle"
      v-model="form"
    ></v-select>

    <v-row>
      <v-col>
        <v-text-field
          v-model="form.number_times"
          label="Nombre de fois"
          disabled
        ></v-text-field>
      </v-col>

      <v-col>
        <v-text-field
          v-model="form.days_interval"
          label="Cycle Par jour"
          disabled
        ></v-text-field>
      </v-col>

      <v-col>
        <v-text-field
          v-model="form.hours_interval"
          label="Cycle Par Heure"
          disabled
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
      form: {
        number_times: 0,
        days_interval: 0,
        hours_interval: 0,
      },
    };
  },
  methods: {
    setCycle(cycle) {
      if (cycle != null) {
        this.form = cycle;
      }
    },
  },

  watch: {
    form() {
      this.$emit("change", this.form);
    },
  },
};
</script>

<style lang="" scoped>
</style>