<template>
  <div>
    <v-dialog v-model="dialog" max-width="700" persistent>
      <v-card>
        <v-card-title> Recadrer l'image </v-card-title>

        <v-divider />

        <v-card-text class="pa-8">
          <v-row>
            <v-col cols="12">
              <cropper
                ref="cropper"
                :src="url"
                class="cropper"
                @change="onChange"
              />
            </v-col>

            <!-- <v-col cols="12" md="6" style="background-color: grey">
              <h3 class="mb-4 white--text">Résultat</h3>
              <div
                style="
                  height: 400px;
                  overflow: hidden;
                  margin-left: 40px;
                  border-radius: 8px;
                "
              >
                <div style="position: relative">
                  <div style="position: absolute; top: 0; left: 0">
                    <img
                      src="../assets/iPhone.png"
                      style="
                        width: 300px;
                        margin-right: 40px;
                        border: 1px solid #ddd;
                      "
                    />
                  </div>
                  <div style="height: 209px; left: 12px; top: 83px">
                    <preview
                      :width="120"
                      :height="209"
                      :image="result.image"
                      :coordinates="result.coordinates"
                    />
                  </div>
                </div>
              </div>
              <div></div>
            </v-col> -->
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" depressed text @click="save">
            <v-icon left>mdi-check-circle</v-icon>
            Suavgarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Cropper, Preview } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  components: {
    Cropper
  },
  data() {
    return {
      dialog: false,
      file: null,
      result: {
				coordinates: null,
				image: null
			}
    };
  },
  methods: {
    open(file) {
      this.file = file;
      this.dialog = true;
    },
    onChange({ coordinates, image }) {
			this.result = {
				coordinates,
				image
			};
		},
    save() {
      const { coordinates, image, visibleArea, canvas } =
        this.$refs.cropper.getResult();

      this.$emit("cropped", canvas);
      this.file = null;
      this.dialog = false;
    },
  },
  computed: {
    url() {
      if (this.file == null) {
        return "";
      }
      return URL.createObjectURL(this.file);
    },
  },
};
</script>