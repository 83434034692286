<template>
  <div>
    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card class="text-center">
        <v-card-text class="pa-6">
          <v-icon
            color="primary"
            size="80"
            class="animate__animated animate__rotateIn"
          >
            mdi-bell-badge
          </v-icon>
          <h2 class="text--primary mt-3">Envoyer une notification</h2>
          <p class="mt-5 mb-5">Voulez vous vraiment executer cette action ?</p>

          <v-btn text class="rounded-lg mr-2 text-none" large @click="close()">
            <v-icon left>mdi-close</v-icon> Annuler
          </v-btn>
          <v-btn
            class="rounded-lg text-none"
            large
            color="primary"
            depressed
            :loading="loading"
            @click="confirm()"
          >
            <v-icon left>mdi-check-circle-outline</v-icon> Confirmer
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
    
    <script>
import { HTTP } from "@/http-common";
export default {
  data() {
    return {
      dialog: false,
      item: null,
      loading: false,

      form: {
        positioning: [],
      },
      formErrors: {},
    };
  },
  methods: {
    open(item) {
      this.item = item;
      this.form.positioning = item.positioning;
      this.dialog = true;
    },
    confirm() {
      this.loading = true;
      HTTP.post("/V2/publicities/notification/" + this.item.id, this.form)
        .then(() => {
          this.loading = false;

          this.$successMessage = "Notification envoyée";
          this.close();
        })
        .catch((err) => {
          this.loading = false;
          this.$errorMessage = "Une erreur s'est produite";
          console.log(err);
        });
    },
    close() {
      this.dialog = false;
      this.item = null;
    },
  },
};
</script>