<template>
  <v-row v-if="isLoading">
    <v-col cols="6" lg="3" v-for="item in 4" :key="item">
      <v-card flat class="rounded-lg">
        <v-card-text>
          <v-skeleton-loader type="article"></v-skeleton-loader>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6" lg="6" v-for="item in 4" :key="'card_'+item">
      <v-card flat class="rounded-lg">
        <v-card-text>
          <v-skeleton-loader type="article"></v-skeleton-loader>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <div v-else>
    <v-row>
      <!-- Total -->
      <v-col cols="12" lg="3" md="6">
        <v-sheet
          :rounded="'lg'"
          class="text-center d-flex align-center"
        >
          <v-card-title class="align-center text-center">
            <v-avatar
              class="elevation-3 text-center"
              color="blue"
              size="55"
              style="margin: auto"
            >
              <v-icon color="white "> mdi-percent</v-icon>
            </v-avatar>
          </v-card-title>

          <v-card-text class="align-start align-center mt-4">
            <p class="font-weight-bold text-lg mb-1 mt-2">Nombre total</p>
            <p class="font-weight-black text-lg mb-1 title">
              {{ stats.total }}
            </p>
          </v-card-text>
        </v-sheet>
      </v-col>

      <!-- total_vaildated -->
      <v-col cols="12" lg="3" md="6">
        <v-sheet
          :rounded="'lg'"
          class="text-center d-flex align-center"
          style="height: 100%"
        >
          <v-card-title class="align-center text-center">
            <v-avatar
              class="elevation-3 text-center"
              color="success"
              size="55"
              style="margin: auto"
            >
              <v-icon color="white "> mdi-check</v-icon>
            </v-avatar>
          </v-card-title>

          <v-card-text class="align-start align-center mt-4">
            <p class="font-weight-bold text-lg mb-1 mt-2">Validées</p>
            <p class="font-weight-black text-lg mb-1 title">
              {{ stats.total_validated }}
            </p>
          </v-card-text>
        </v-sheet>
      </v-col>

      <!-- total_vaildated -->
      <v-col cols="12" lg="3" md="6">
        <v-sheet
          :rounded="'lg'"
          class="text-center d-flex align-center"
          style="height: 100%"
        >
          <v-card-title class="align-center text-center">
            <v-avatar
              class="elevation-3 text-center"
              color="gifty"
              size="55"
              style="margin: auto"
            >
              <v-icon color="white "> mdi-check</v-icon>
            </v-avatar>
          </v-card-title>

          <v-card-text class="align-start align-center mt-4">
            <p class="font-weight-bold text-lg mb-1 mt-2">Actives</p>
            <p class="font-weight-black text-lg mb-1 title">
              {{ stats.total_active }}
            </p>
          </v-card-text>
        </v-sheet>
      </v-col>

      <!-- total_vaildated -->
      <v-col cols="12" lg="3" md="6">
        <v-sheet
          :rounded="'lg'"
          class="text-center d-flex align-center"
          style="height: 100%"
        >
          <v-card-title class="align-center text-center">
            <v-avatar
              class="elevation-3 text-center"
              color="primary"
              size="55"
              style="margin: auto"
            >
              <v-icon color="white "> mdi-clock</v-icon>
            </v-avatar>
          </v-card-title>

          <v-card-text class="align-start align-center mt-4">
            <p class="font-weight-bold text-lg mb-1 mt-2">En attente</p>
            <p class="font-weight-black text-lg mb-1 title">
              {{ stats.total_pending }}
            </p>
          </v-card-text>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <v-sheet :rounded="'lg'">
          <v-card-title class="align-start d-flex">
            <v-avatar class="elevation-3" color="info" size="38">
              <v-icon color="white"> mdi-ticket-percent </v-icon>
            </v-avatar>
            <span class="font-weight-bold text-center ml-3">
              Taux de visibilité</span
            >
          </v-card-title>

          <v-card-text class="align-start mt-2">
            <DoughnutChart
              :labels="[
                'Publicités Vu',
                'Non vu'
              ]"
              :data="[
                stats.total_seen,
                stats.total_active  - stats.total_seen
              ]"
              cutout="0"
            />
          </v-card-text>
        </v-sheet>
      </v-col>

      <v-col cols="12" lg="6">
        <v-sheet :rounded="'lg'">
          <v-card-title class="align-start d-flex">
            <v-avatar class="elevation-3" color="info" size="38">
              <v-icon color="white"> mdi-ticket-percent </v-icon>
            </v-avatar>
            <span class="font-weight-bold text-center ml-3">
              Taux d'interaction</span
            >
          </v-card-title>

          <v-card-text class="align-start mt-2">
            <DoughnutChart
              :labels="[
                'Publicités visité',
                'Non visité'
              ]"
              :data="[
                stats.total_clicked,
                stats.total_seen - stats.total_clicked
              ]"
              cutout="0"
            />
          </v-card-text>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { HTTP } from "@/http-common";

import DoughnutChart from "@/components/charts/DoughnutChart.vue";

export default {
  props: ["filter"],
  components: {DoughnutChart},
  data() {
    return {
      isLoading: false,
      stats: [],
    };
  },

  methods: {
    fetchData() {
      this.isLoading = true;
      let url = "/V2/publicities/statistiques";

      HTTP.get(url, {
        params: {
          ...this.filter,
        },
      })
        .then((res) => {
          this.isLoading = false;
          this.stats = res.data;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>