<template>
  <div>
    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card class="text-center">
        <v-card-text class="pa-6">
          <v-icon
            color="primary"
            size="80"
            class="animate__animated animate__rotateIn"
          >
            mdi-check
          </v-icon>
          <h2 class="text--primary mt-3">Valider une publicité</h2>
          <p class="mt-5 mb-5">
            Veuillez choisir l'emplacement de cette publicite ?
          </p>

          <v-select
            v-if="can('admin')"
            v-model="form.positioning"
            dense
            chips
            deletable-chips
            :items="publicityPositionings()"
            label="Emplacement"
            multiple
            @input="
              formErrors && formErrors.positioning
                ? (formErrors.positioning = '')
                : false
            "
            :error-messages="formErrors.positioning"
          >
          </v-select>

          <v-btn text class="rounded-lg mr-2 text-none" large @click="close()">
            <v-icon left>mdi-close</v-icon> Annuler
          </v-btn>
          <v-btn
            class="rounded-lg text-none"
            large
            color="primary"
            depressed
            @click="confirm()"
          >
            <v-icon left>mdi-check-circle-outline</v-icon> Confirmer
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
import { HTTP } from "@/http-common";
export default {
  data() {
    return {
      dialog: false,
      item: null,

      form: {
        positioning: [],
      },
      formErrors: {}
    };
  },
  methods: {
    open(item) {
      this.item = item;
      this.form.positioning = item.positioning;
      this.dialog = true;
    },
    confirm() {
      HTTP.post("/V2/publicities/validate/" + this.item.id, this.form)
        .then(() => {
          this.$successMessage = "Publicité mise a jour";
          this.$emit("refresh");
          this.close();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    close() {
      this.dialog = false;
      this.item = null;
      this.form.positioning = [];
    },
  },
};
</script>