<template>
  <v-container fluid>
    <div class="main">
      <Statistiques :filter="filter" ref="statistiques" />

      <v-row>
        <v-col cols="6">
          <InteractionByTime
            axis="hour"
            title="Intéractions par Heure"
            :filter="filter"
          />
        </v-col>
        <v-col cols="6">
          <InteractionByTime
            axis="month"
            title="Intéractions par Mois"
            :filter="filter"
          />
        </v-col>
      </v-row>

      <v-row v-if="isLoading">
        <v-col cols="12">
          <v-card class="rounded-lg" flat>
            <v-skeleton-loader type="table"></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="12">
          <v-card class="rounded-lg" flat>
            <v-card-text>
              <div class="d-flex justify-space-between mb-2">
                <div></div>
                <div class="d-flex justify-space-between">
                  <v-text-field
                    v-model="filter.keyword"
                    rounded
                    filled
                    dense
                    style="width: 300px"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                    clearable
                    placeholder="Rechercher..."
                    v-on:keyup.enter="fetchData"
                  />

                  <FilterDialog @filter="[filterBy($event)]" :filter="filter" />

                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        elevation="0"
                        depressed
                        icon
                        @click="refresh"
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon>mdi-refresh</v-icon>
                      </v-btn>
                    </template>
                    <span>Actualiser</span>
                  </v-tooltip>

                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ml-2"
                        color="primary"
                        elevation="0"
                        depressed
                        rounded
                        @click="addPublicity"
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Nouvelle Publicité</span>
                  </v-tooltip>
                </div>
              </div>
              <v-divider />
              <div v-if="isLoading">
                <v-skeleton-loader type="table"></v-skeleton-loader>
              </div>
              <div v-else>
                <div v-if="publicities.data.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th></th>
                          <th v-if="can('admin')">Envoyer Notif</th>
                          <th>Active ?</th>
                          <th class="font-weight-bold">ID</th>
                          <th class="font-weight-bold">Image</th>
                          <th class="font-weight-bold">Title</th>
                          <th class="font-weight-bold">Type</th>
                          <th class="font-weight-bold">Start</th>
                          <th class="font-weight-bold">End</th>
                          <th class="font-weight-bold">Active</th>
                          <th class="font-weight-bold">Validée ?</th>
                          <th class="font-weight-bold">Notification ?</th>

                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in publicities.data" :key="item.id">
                          <td>
                            <v-checkbox
                              v-model="selectedPubs"
                              label=""
                              :value="item.id"
                            ></v-checkbox>
                          </td>
                          <td v-if="can('admin')">
                            <v-btn @click="sendNotification(item)" icon
                              
                              :disabled="!item.is_active || !item.validated">
                              <v-icon class="success--text">mdi-send-circle</v-icon>
                            </v-btn>
                          </td>
                          <td>
                            <v-switch
                              hide-details
                              class="ma-0 pa-0"
                              :input-value="item.is_active"
                              readonly
                              @click="changeStatus(item.id)"
                              color="blue"
                            ></v-switch>
                          </td>

                          <td class="font-weight-bold">
                            {{ item.id }}
                          </td>

                          <td class="font-weight-bold">
                            <v-img
                              :src="getImageLink(item.image)"
                              width="30"
                              height="30"
                            ></v-img>
                          </td>

                          <td class="font-weight-bold">
                            {{ item.name }}
                          </td>
                          <td class="font-weight-bold">
                            {{ setType(item.type) }}
                          </td>
                          <td class="font-weight-bold">
                            {{ item.start_date }}
                          </td>
                          <td class="font-weight-bold">
                            {{ item.end_date }}
                          </td>

                          <td>
                            <v-chip
                              :color="setStatusColor(item.is_active)"
                              label
                              small
                              dark
                            >
                              {{ setStatus(item.is_active) }}
                            </v-chip>
                          </td>

                          <td>
                            <v-switch
                              v-if="$store.getters.getUser.roleName == 'admin'"
                              hide-details
                              class="ma-0 pa-0"
                              :input-value="item.validated"
                              readonly
                              @click="validatePublicity(item)"
                              color="blue"
                            ></v-switch>

                            <v-chip
                              v-else
                              :color="setValidatedColor(item.validated)"
                              label
                              small
                              dark
                            >
                              {{ setValidated(item.validated) }}
                            </v-chip>
                          </td>
                          <td class="text-center">
                            <v-tooltip
                              top
                              color="success"
                              v-if="item.notification_state == 1"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  class="success--text"
                                  >mdi-check</v-icon
                                >
                              </template>
                              <span>Envoyée</span>
                            </v-tooltip>

                            <v-tooltip
                              top
                              color="blue"
                              v-if="item.notification_state == 0"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  class="blue--text"
                                  >mdi-timer-sand-complete</v-icon
                                >
                              </template>
                              <span>En attente</span>
                            </v-tooltip>

                            <v-tooltip
                              top
                              color="error"
                              v-if="item.notification_state == 2"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  class="error--text"
                                  >mdi-close</v-icon
                                >
                              </template>
                              <span>Non Envoyée</span>
                            </v-tooltip>
                          </td>
                          <td>
                            <v-menu
                              transition="slide-x-transition"
                              bottom
                              right
                            >
                              <v-list dense>
                                <v-list-item @click="showpub(item)">
                                  <v-list-item-icon>
                                    <v-icon color="black">mdi-eye</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>Details</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="analysePub(item)">
                                  <v-list-item-icon>
                                    <v-icon color="black">mdi-clock</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title
                                    >Analyses</v-list-item-title
                                  >
                                </v-list-item>

                                <v-list-item @click="updatePublicity(item)">
                                  <v-list-item-icon>
                                    <v-icon color="black">mdi-pencil</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title
                                    >Modifier</v-list-item-title
                                  >
                                </v-list-item>

                                <v-list-item @click="deletePublicity(item.id)">
                                  <v-list-item-icon>
                                    <v-icon color="red">mdi-delete</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title
                                    >Supprimer</v-list-item-title
                                  >
                                </v-list-item>
                              </v-list>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="black"
                                  icon
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>
                            </v-menu>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div class="d-flex mt-4">
                    <span class="mt-2 mr-2">Lignes par page :</span>
                    <v-select
                      filled
                      v-model="filter.size"
                      style="width: 20px"
                      dense
                      :items="[10, 20, 30, 40, 50]"
                      @change="onPageChange"
                    ></v-select>
                    <v-spacer />
                    <v-pagination
                      total-visible="6"
                      v-if="publicities.total > 15"
                      circle
                      v-model="filter.page"
                      :length="Math.ceil(publicities.total / filter.size)"
                      @input="onPageChange"
                    ></v-pagination>
                  </div>
                </div>
                <div v-else>
                  <div class="text-center">
                    <v-avatar tile size="200">
                      <v-img :src="require('@/assets/database.svg')"></v-img>
                    </v-avatar>
                    <h3 class="text--primary d-block mb-2">OOPS !</h3>
                    <p>Aucun enregistrement correspondant trouvé.</p>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <AddPublicityDialog ref="addPublicityDialog" @refresh="fetchData" />
      <UpdatePublicityDialog ref="updatePublicityDialog" @refresh="fetchData" />
      <ShowDialog ref="showpub" />
      <ValidateDialog ref="validateDialog" @refresh="fetchData" />
      <SendNotificationDialog ref="sendNotification"/>
    </div>
  </v-container>
</template>

<script>
import AddPublicityDialog from "./components/AddPublicityDialog.vue";
import UpdatePublicityDialog from "./components/UpdatePublicityDialog.vue";

import ShowDialog from "./components/ShowDialog.vue";

import FilterDialog from "./components/FilterDialog.vue";

import { HTTP } from "@/http-common";

import InteractionByTime from "./analyse/InteractionByTime.vue";

import Statistiques from "./components/Statistiques.vue";
import ValidateDialog from "./components/ValidateDialog.vue";
import SendNotificationDialog from "./components/SendNotificationDialog.vue";
export default {
  components: {
    AddPublicityDialog,
    UpdatePublicityDialog,
    ShowDialog,
    FilterDialog,
    InteractionByTime,
    Statistiques,
    ValidateDialog,
    SendNotificationDialog
  },
  data() {
    return {
      filter: {
        startDate: "",
        endDate: "",
        type: null,
        validated: "all",
        active: "all",
        keyword: "",
        size: 10,
        page: 1,
      },
      pagination: {
        current: 1,
        total: 0,
      },
      delete_id: null,
      selectedPubs: [],

      hoursGraph: [],
    };
  },
  methods: {
    sendNotification(item){
      this.$refs.sendNotification.open(item);
    },
    refresh() {
      this.filter = {
        startDate: "",
        endDate: "",
        type: null,
        validated: "all",
        active: "all",
        keyword: "",
      };
      this.fetchData();
    },
    fetchData() {
      this.$store.dispatch("fetchPublicities", {
        filter: this.filter,
      });

      this.$refs.statistiques.fetchData();
    },
    getImageLink(file) {
      return process.env.VUE_APP_FILE_URL + file;
    },
    setStatusColor(status) {
      switch (status) {
        case 0:
          return "warning ";
        case 1:
          return "success ";
      }
    },
    setStatus(status) {
      switch (status) {
        case 0:
          return "Non visible";
        case 1:
          return "Active";
      }
    },
    setValidatedColor(status) {
      switch (status) {
        case 0:
          return "warning ";
        case 1:
          return "success ";
      }
    },
    setValidated(status) {
      switch (status) {
        case 0:
          return "Non Validée";
        case 1:
          return "Validée";
      }
    },

    setType(type) {
      switch (type) {
        case 0:
          return "Banner";
        case 1:
          return "POP UP";
          case 2:
          return "Fullscreen";
      }
    },

    onPageChange() {
      this.fetchData();
    },

    addPublicity() {
      this.$refs.addPublicityDialog.open();
    },
    deletePublicity(id) {
      this.action = "removeOne";
      this.delete_id = id;
      this.$confirm_dialog = true;
    },
    removePublicity() {
      HTTP.delete("/V2/publicities/delete/" + this.delete_id)
        .then(() => {
          this.delete_id = null;
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    updatePublicity(item) {
      this.$refs.updatePublicityDialog.open(item);
    },
    changeStatus(id) {
      HTTP.post("/V2/publicities/change-status", {
        id,
      })
        .then(() => {
          this.$successMessage = "Publicité mise a jour";
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    validatePublicity(item) {
      if (item.validated) {
        HTTP.post("/V2/publicities/validate/" + item.id)
          .then(() => {
            this.$successMessage = "Publicité mise a jour";
            this.fetchData();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$refs.validateDialog.open(item);
      }
    },

    showpub(item) {
      this.$refs.showpub.open(item);
    },
    filterBy(value) {
      this.filter.startDate = value.startDate;
      this.filter.endDate = value.endDate;
      this.filter.type = value.type;
      this.filter.validated = value.validated;
      this.filter.active = value.active;

      this.fetchData();
    },

    analysePub(item) {
      this.$router.push("/publicities/analyse/" + item.id);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters.getLoading;
    },
    publicities() {
      return this.$store.getters.getPublicities.data;
    },
  },
  mounted() {
    // fetchPublicities
    this.fetchData();
  },

  watch: {
    $is_confirm: function (el) {
      if (el) {
        if (this.action == "removeOne") {
          this.removePublicity();
        }
        this.action = null;
        this.$is_confirm = false;
      }
    },
  },
};
</script>

<style>
</style>