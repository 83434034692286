<template>
  <v-dialog :max-width="isLoading ? 500 : 700" scrollable v-model="dialog">
    <v-card :loading="isLoading" :disabled="isLoading">
      <v-card-title>
        Détails publicité
        <v-spacer />
        <v-icon @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="12" v-if="publicity">
            <v-card outlined>
              <v-skeleton-loader type="table" v-if="isLoading" />
              <div v-else>
                <a
                  :href="publicity.action"
                  :alt="publicity.action"
                  target="_blank"
                >
                  <v-img
                    :src="getImageLink(publicity.image)"
                    aspect-ratio="1.7"
                    contain
                    v-if="publicity.image"
                  ></v-img>
                </a>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td class="font-weight-bold">Image</td>
                        <td>
                          <v-img
                            v-if="publicity.image"
                            :src="getImageLink(publicity.image)"
                            width="40"
                            height="40"
                            class="zoom-pointer rounded"
                          />

                          <v-img
                            v-else
                            :src="require('@/assets/no-image.png')"
                            width="40"
                            height="40"
                          />
                        </td>
                      </tr>

                      <tr class="bg-snipper">
                        <td class="font-weight-bold">Titre</td>
                        <td>{{ publicity.name || "-" }}</td>
                      </tr>

                      <tr class="bg-snipper">
                        <td class="font-weight-bold">Description</td>
                        <td>{{ publicity.description || "-" }}</td>
                      </tr>

                      <tr>
                        <td class="font-weight-bold">Date de début</td>
                        <td>{{ publicity.start_date }}</td>
                      </tr>

                      <tr class="bg-snipper">
                        <td class="font-weight-bold">Date de fin</td>
                        <td>{{ publicity.end_date }}</td>
                      </tr>

                      <tr class="bg-snipper">
                        <td class="font-weight-bold">Action</td>
                        <td>
                          <a
                            :href="publicity.action"
                            :alt="publicity.action"
                            target="_blank"
                          >
                            {{ publicity.action }}
                          </a>
                        </td>
                      </tr>

                      <tr>
                        <td class="font-weight-bold">Nombre de POS</td>
                        <td>
                          <v-chip color="primary" small>
                            <v-icon small left>mdi-mobile</v-icon>
                            {{ publicity.users.length }}
                          </v-chip>
                        </td>
                      </tr>

                      <tr>
                        <td class="font-weight-bold">Type</td>
                        <td>
                          {{
                            pubTypes.find((x) => x.id === publicity.type).name
                          }}
                        </td>
                      </tr>

                      <tr v-if="publicity.type == 2">
                        <td class="font-weight-bold">Video</td>
                        <td>
                          <a
                            :href="getLinkVideo(publicity.video_link)"
                            alt="Lien video"
                            target="_blank"
                          >
                            {{ publicity.video_link }}
                          </a>
                        </td>
                      </tr>

                      <tr v-if="publicity.type == 2 || publicity.type == 1">
                        <td class="font-weight-bold">Cycle</td>
                        <td>
                          {{ cycleLabel(publicity.cycle) }}
                        </td>
                      </tr>

                      <tr v-if="publicity.type == 0">
                        <td class="font-weight-bold">Emplacement</td>
                        <td>
                          <v-chip
                            color="primary"
                            small
                            v-for="position in publicity.positioning"
                            :key="position"
                          >
                            {{ position }}
                          </v-chip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-card>
          </v-col>

          <v-col>
            <v-card flat>
              <v-card-text>
                <v-skeleton-loader v-if="usersLoading" type="table" />
                <div v-else>
                  <div
                    v-if="in_array([1], $store.getters.getUser.user.role.id)"
                  >
                    <v-simple-table v-if="salepoints && salepoints.length">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th>Nom</th>
                            <th>Email</th>
                            <th>Telephone</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, i) in salepoints" :key="i">
                            <td>
                              <v-avatar
                                v-if="item.image"
                                size="35"
                                class="zoom-pointer"
                              >
                                <v-img :src="getImageLink(item.image)"></v-img>
                              </v-avatar>
                              <v-avatar
                                v-else
                                size="35"
                                class="zoom-pointer"
                                @click="
                                  [
                                    (image = [
                                      { src: require('@/assets/avatar.png') },
                                    ]),
                                    (visible = true),
                                  ]
                                "
                              >
                                <v-img
                                  :src="require('@/assets/avatar.png')"
                                ></v-img>
                              </v-avatar>
                            </td>
                            <td>
                              <span class="text-no-wrap">{{ item.name }}</span>
                            </td>
                            <td>{{ item.email }}</td>
                            <td>{{ item.phone }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <!-- <NoResult v-else size="180" /> -->
                  </div>
                </div>
              </v-card-text>
            </v-card>

            <v-divider v-if="total > totalPerPage" />
            <v-card-actions v-if="total > totalPerPage">
              <div class="w-100">
                <v-select
                  dense
                  filled
                  hide-details
                  :items="[10, 20, 30, 50]"
                  v-model="perPage"
                  @change="getSalepointsByIds"
                ></v-select>
              </div>
              <v-spacer />
              <v-pagination
                total-visible="10"
                circle
                v-model="pagination.current"
                :length="pagination.total"
                @input="getSalepointsByIds"
              >
              </v-pagination>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
  
  <script>
import { HTTP } from "@/http-common";

export default {
  components: {},
  data() {
    return {
      dialog: false,
      isLoading: false,
      usersLoading: false,
      publicity: null,
      totalPerPage: 10,

      ids: [],
      filter: {
        databaseIds: [],
      },
      salepoints: [],
      total: 0,
      perPage: 10,
      pagination: {
        current: 1,
        total: 0,
      },
    };
  },
  methods: {
    open(publicity) {
      //this.publicity = publicity
      this.points = [];
      this.dialog = true;
      this.getPublicity(publicity.id);
    },
    close() {
      this.dialog = false;
    },
    getPublicity(id) {
      //this.$Progress.start()
      this.isLoading = true;
      HTTP.get("/V2/publicities/show/" + id)
        .then((res) => {
          //console.log(res)

          this.publicity = res.data.data;
          res.data.data.users.forEach((item) => {
            this.ids.push(item.id);
          });

          //this.$Progress.finish()
          this.isLoading = false;
          this.getSalepointsByIds();
        })
        .catch((err) => {
          //this.$Progress.fail()
          //this.$errorMessage = this.$i18n.t('message.catchError')
          this.isLoading = false;
          console.log(err);
        });
    },
    getSalepointsByIds() {
      //this.$Progress.start()
      this.usersLoading = true;

      HTTP.get(
        "/V2/publicities/salepoints-by-ids?page=" + this.pagination.current,
        {
          params: {
            perPage: this.perPage,
            ids: this.salepointIds ? this.salepointIds : this.ids,
          },
        }
      )
        .then((res) => {
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.total = res.data.total;
          this.salepoints = res.data.data;
          //this.$Progress.finish()
          this.usersLoading = false;
        })
        .catch((err) => {
          //this.$Progress.fail()
          this.usersLoading = false;
          console.log(err);
        });
    },

    getLinkVideo(link) {
      if (link.startsWith("/uploads")) {
        return this.getImageLink(link);
      }
      return link;
    },
    cycleLabel(cycle) {
      var result = ""
      this.pubCycles.forEach((el) => {
        
          if (
            el.value.days_interval == cycle.days_interval &&
            el.value.hours_interval == cycle.hours_interval &&
            el.value.number_times == cycle.number_times
          ) {
            result = el.label;
          }
        
      });
      return result;
    },
  },

  created() {},
  watch: {},
};
</script>
  
  <style scoped>
</style>