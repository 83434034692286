<template>
  <div>
    <v-dialog v-model="dialog" max-width="700"  persistent>
      <v-card>
        <v-card-title>
          Selectionner des utilisateurs
          <v-spacer />
          <v-btn icon class="v-btn--active" color="primary" @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-6" style="overflow-y: scroll; max-height: 450px;">
          <v-tabs v-model="tab" background-color="accent-4" centered primary>
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
              <v-icon>mdi-filter</v-icon>
              Filtrage
            </v-tab>

            <v-tab href="#tab-2" v-if="can('admin')">
              <v-icon>mdi-account</v-icon>
              POS
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <v-card>
                <v-card-text>
                  <form @submit.prevent="filterUsers">
                    <v-select
                      v-if="can('admin')"
                      label="Versions"
                      :loading="isVersionsLoading"
                      :items="versions"
                      multiple
                      v-model="filter.versions"
                      class="mb-3"
                      hide-details=""
                      clearable
                      chips
                      small-chips
                      deletable-chips
                    />

                    <v-select
                      label="Pays"
                      :loading="isDBLoading"
                      :items="countries"
                      item-value="id"
                      item-text="name"
                      multiple
                      v-model="filter.countries"
                      class="mb-3"
                      hide-details=""
                      clearable
                      chips
                      small-chips
                      deletable-chips
                    />

                    <v-autocomplete
                      hide-details
                      label="Wilaya"
                      clearable
                      chips
                      small-chips
                      deletable-chips
                      multiple
                      v-model="filter.selectedWilayas"
                      @change="filterRegion"
                      :items="$store.state.wilayas"
                      item-value="id"
                      item-text="name"
                      class="mb-3"
                    ></v-autocomplete>

                    <v-autocomplete
                      class="mb-3"
                      hide-details
                      label="Commune"
                      :disabled="filter.selectedWilayas.length === 0"
                      clearable
                      chips
                      small-chips
                      deletable-chips
                      multiple
                      v-model="filter.selectedCommunes"
                      :items="communes"
                      item-value="id"
                      item-text="name"
                    ></v-autocomplete>

                    <v-select
                      label="Base de données"
                      :loading="isDBLoading"
                      :items="databases"
                      item-value="id"
                      item-text="name"
                      multiple
                      v-model="filter.databases"
                      class="mb-3"
                      hide-details=""
                      clearable
                      chips
                      small-chips
                      deletable-chips
                    />

                    <v-select
                      v-if="can('admin')"
                      label="Catégories"
                      :loading="isCategoryLoading"
                      :items="categories"
                      item-value="id"
                      item-text="name"
                      multiple
                      v-model="filter.categories"
                      class="mb-3"
                      hide-details=""
                      clearable
                      chips
                      small-chips
                      deletable-chips
                    />

                    <v-select
                      v-if="can('admin')"
                      label="Role"
                      :loading="isRoleLoading"
                      :items="roles"
                      item-value="id"
                      item-text="name"
                      multiple
                      v-model="filter.roles"
                      hide-details=""
                      clearable
                      chips
                      small-chips
                      deletable-chips
                      class="mb-4"
                    />
                    <v-row>
                      <v-col cols="12" class="text-center">
                        <p class="text-left">
                          Montant:
                        </p>
                        <v-range-slider
                          v-if="!rangeLoading"
                          v-model="filter.range"
                          :max="max"
                          :min="0"
                          hide-details
                          class="align-center mt-5 pt-5 mb-4"
                          thumb-label="always"
                        >
                        <template v-slot:prepend>
                          <v-text-field
                            :value="filter.range[0]"
                            class="mt-0 pt-0"
                            hide-details
                            single-line
                            type="number"
                            style="width: 60px"
                            @change="$set(filter.range, 0, $event)"
                          ></v-text-field>
                        </template>

                          <template v-slot:thumb-label="item">
                            <v-btn
                              class="primary mt-4"
                              small
                              elevation="0"
                              style="font-size: 10px"
                            >
                              {{ CurrencyFormatting(item.value) }} DZ
                            </v-btn>
                          </template>

                          <template v-slot:append>
                            <v-text-field
                              :value="filter.range[1]"
                              class="mt-0 pt-0"
                              hide-details
                              single-line
                              type="number"
                              style="width: 80px"
                              @change="$set(filter.range, 1, $event)"
                            ></v-text-field>
                          </template>
                        </v-range-slider>
                        
                        <v-progress-circular v-else
                            indeterminate
                            class="mt-4"
                            color="amber"
                            style="margin: auto;"
                          ></v-progress-circular>
                        
                      </v-col>
                    </v-row>

                    <p class="d-flex justify-space-between mt-5">
                      <v-btn
                        depressed
                        large
                        color="secondary"
                        @click="dialog = false"
                        class="mt-3"
                      >
                        <v-icon left>mdi-arrow-left</v-icon>
                        Retour
                      </v-btn>

                      <v-btn
                        depressed
                        class="rounded-lg text-none mt-3"
                        color="primary"
                        large
                        type="submit"
                        :loading="btnLoading"
                      >
                        Filtrer
                      </v-btn>
                    </p>
                  </form>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item value="tab-2" v-if="can('admin')">
              <v-card>
                <v-card-text>
                  <div v-if="isLoading">
                    <v-skeleton-loader type="table"></v-skeleton-loader>
                  </div>

                  <div v-else>
                    <div v-if="filtredUsers.length > 0">
                      <v-data-table
                        :items="filtredUsers"
                        :hide-default-header="false"
                        :hide-default-footer="false"
                        item-key="id"
                        class="elevation-0"
                      >
                        <template v-slot:item="{ item }">
                          <tr>
                            <td class="font-weight-bold">
                              <v-checkbox
                                v-model="selectedUsers"
                                :value="item"
                                :value-comparator="comparator"
                              />
                            </td>
                            <td class="font-weight-bold">
                              {{ item.id }}
                            </td>

                            <td class="font-weight-bold">
                              <v-avatar size="35">
                                <v-img :src="require('@/assets/avatar.png')" />
                              </v-avatar>
                            </td>

                            <td class="font-weight-bold text-no-wrap">
                              {{ item.name }}
                            </td>
                            <td class="font-weight-bold text-no-wrap">
                              <v-icon>mdi-phone</v-icon> {{ item.phone }}
                            </td>
                            <td class="font-weight-bold text-no-wrap">
                              <v-icon>mdi-email-outline</v-icon> {{ item.email }}
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>

                    <div v-else>
                      <div class="text-center">
                        <v-avatar tile size="200">
                          <v-img
                            :src="require('@/assets/database.svg')"
                          ></v-img>
                        </v-avatar>
                        <h3 class="text--primary d-block mb-2">OOPS !</h3>
                        <p>Aucun enregistrement correspondant trouvé.</p>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>

          <div v-if="selectedUsers.length > 0" class="mt-3">
            <v-divider></v-divider>
            <div class="d-flex justify-space-between mt-4">
              <div>
                <p class="fs-16">
                  <v-icon color="primary" size="20" v-if="selectedUsers.length"
                    >mdi-check-circle-outline</v-icon
                  >
                  <v-icon color="primary" size="20" v-else
                    >mdi-close-circle-outline</v-icon
                  >

                  <b>Utilisateurs</b>
                  <strong class="primary--text text-decoration-underline">
                    {{ selectedUsers.length }}
                  </strong>
                </p>
              </div>
              <v-btn
                color="success"
                v-if="ids.length"
                large
                depressed
                @click="Validate"
                :class="'ml-2'"
              >
                <v-icon left>mdi-check-circle</v-icon>
                Valider
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/http-common";

export default {
  data() {
    return {
      tab: null,
      btnLoading: false,
      isLoading: false,

      isDBLoading: false,
      isRoleLoading: false,
      isCategoryLoading: false,
      isCountriesLoading: false,
      isVersionsLoading: false,
      rangeLoading: true,

      countries: [],
      roles: [],
      categories: [],
      databases: [],
      versions: [],

      max: 1000,

      filter: {
        selectedWilayas: [],
        selectedCommunes: [],
        countries: [],
        roles: [],
        categories: [],
        databases: [],
        versions: [],
        range: [],
      },
      dialog: false,
      filtredUsers: [],

      selectedUsers: [],
      ids: [],
    };
  },
  methods: {
    filterUsers() {
      this.btnLoading = true;
      this.isLoading = true;
      //filter_users
      HTTP.post("/V2/publicities/filter_users", this.filter)
        .then((res) => {
          this.btnLoading = false;
          this.isLoading = false;
          this.selectedUsers = res.data.users;
          this.filtredUsers = res.data.users;
          this.ids = res.data.ids;
        })
        .catch((err) => {
          this.btnLoading = false;
          this.isLoading = false;
          this.formErrors = err.response.data.errors;
        });
    },
    open() {
      this.dialog = true;
      if (this.wilayas.length == 0) this.$store.dispatch("fetchwilayas");

      if (!this.roles.length) {
        this.getRoles();
      }
      if (!this.databases.length) {
        this.getDatabases();
      }

      if (!this.categories.length) {
        this.getCategories();
      }
      if (!this.countries.length) {
        this.getCountries();
      }
      if (!this.versions.length) {
        this.getVersions();
      }
      if (!this.filter.range.length) {
        this.getRange();
      }
    },
    close() {
      this.dialog = false;
    },

    Validate() {
      this.dialog = false;
      var ids = [];
      this.selectedUsers.forEach((element) => {
        ids.push(element.id);
      });
      this.$emit("selected", {
        users: ids,
        filter: JSON.stringify(this.filter)
      });
    },

    filterRegion() {
      var numeric_array = [];
      for (var item in this.filter.selectedWilayas) {
        numeric_array.push(this.filter.selectedWilayas[item]);
      }
      this.$store.dispatch("fetchCommunes", {
        filter: numeric_array,
      });
    },
    setUsers(users, ids, filter) {
      if (users != null) {
        this.selectedUsers = users;
        this.filtredUsers = users;
      } else {
        this.selectedUsers = [];
        this.filtredUsers = [];
      }

      this.ids = ids;

      if(filter){
        this.filter = filter;
      }

      this.getRange();
      
    },
    comparator(a, b) {
      return a.id == b.id;
    },

    getCountries() {
      this.isCountriesLoading = true;
      HTTP.get("/v1/users/countries")
        .then((res) => {
          this.isCountriesLoading = false;
          this.countries = res.data.data;
        })
        .catch((err) => {
          this.isCountriesLoading = false;
          console.log(err);
        });
    },
    getCategories() {
      this.isCategoryLoading = true;
      HTTP.get("/v1/users/categories")
        .then((res) => {
          this.isCategoryLoading = false;
          this.categories = res.data.data;
        })
        .catch((err) => {
          this.isRoleLoading = false;
          console.log(err);
        });
    },

    getWilayas() {
      this.isWilayasLoading = true;
      HTTP.get("/v1/users/wilayas")
        .then((res) => {
          this.isWilayasLoading = false;
          this.wilayas = res.data.data;
        })
        .catch((err) => {
          this.isWilayasLoading = false;
          console.log(err);
        });
    },
    getRoles() {
      this.isRoleLoading = true;
      HTTP.get("/v1/users/roles")
        .then((res) => {
          this.isRoleLoading = false;
          this.roles = res.data.data;
        })
        .catch((err) => {
          this.isRoleLoading = false;
          console.log(err);
        });
    },
    getDatabases() {
      this.isDBLoading = true;
      HTTP.get("/v1/users/databases")
        .then((res) => {
          this.isDBLoading = false;
          this.databases = res.data.data;
        })
        .catch((err) => {
          this.isDBLoading = false;
          console.log(err);
        });
    },

    getVersions() {
      this.isVersionsLoading = true;
      HTTP.get("/v1/users/versions")
        .then((res) => {
          this.isVersionsLoading = false;
          this.versions = res.data.data;
        })
        .catch((err) => {
          this.isVersionsLoading = false;
          console.log(err);
        });
    },

    getRange() {
      this.rangeLoading = true;
      HTTP.get("v1/users/min_max_accounts")
        .then((res) => {
          this.max = res.data.max;
          this.filter.range = [res.data.min, res.data.max];

          this.rangeLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.rangeLoading = false;
        });
    },
  },
  computed: {
    wilayas() {
      return this.$store.getters.getWilayas;
    },

    communes() {
      return this.$store.getters.getCommunes;
    },
  },

  mounted() {},
};
</script>